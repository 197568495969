/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./episode-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../videos/videos-panel/videos-panel.component.ngfactory";
import * as i3 from "../../../videos/videos-panel/videos-panel.component";
import * as i4 from "../../../../../common/core/ui/dialogs/modal.service";
import * as i5 from "@ngxs/store";
import * as i6 from "../../../videos/video.service";
import * as i7 from "../../../../../common/auth/current-user";
import * as i8 from "../../title-page-container/title-primary-details-panel/title-primary-details-panel.component.ngfactory";
import * as i9 from "../../title-page-container/title-primary-details-panel/title-primary-details-panel.component";
import * as i10 from "../../title-urls.service";
import * as i11 from "../../../../../common/core/config/settings.service";
import * as i12 from "../../../../../common/core/translations/translations.service";
import * as i13 from "../../../../../common/core/ui/toast.service";
import * as i14 from "../../title-page-container/title-secondary-details-panel/title-secondary-details-panel.component.ngfactory";
import * as i15 from "../../title-page-container/title-secondary-details-panel/title-secondary-details-panel.component";
import * as i16 from "@angular/common";
import * as i17 from "../../title-page-container/title-cast-panel/title-cast-panel.component.ngfactory";
import * as i18 from "../../title-page-container/title-cast-panel/title-cast-panel.component";
import * as i19 from "../media-item-header/media-item-header.component.ngfactory";
import * as i20 from "../media-item-header/media-item-header.component";
import * as i21 from "../../../../../common/core/ui/ad-host/ad-host.component.ngfactory";
import * as i22 from "../../../../../common/core/ui/ad-host/ad-host.component";
import * as i23 from "../../../../../common/core/utils/lazy-loader.service";
import * as i24 from "../../../shared/footer/footer.component.ngfactory";
import * as i25 from "../../../shared/footer/footer.component";
import * as i26 from "./episode-page.component";
var styles_EpisodePageComponent = [i0.styles];
var RenderType_EpisodePageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_EpisodePageComponent, data: {} });
export { RenderType_EpisodePageComponent as RenderType_EpisodePageComponent };
function View_EpisodePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "videos-panel", [], null, null, null, i2.View_VideosPanelComponent_0, i2.RenderType_VideosPanelComponent)), i1.ɵdid(1, 49152, null, 0, i3.VideosPanelComponent, [i4.Modal, i5.Store, i6.VideoService, i1.ChangeDetectorRef, i7.CurrentUser], { mediaItem: [0, "mediaItem"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EpisodePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "title-primary-details-panel", [], null, null, null, i8.View_TitlePrimaryDetailsPanelComponent_0, i8.RenderType_TitlePrimaryDetailsPanelComponent)), i1.ɵdid(2, 49152, null, 0, i9.TitlePrimaryDetailsPanelComponent, [i10.TitleUrlsService, i11.Settings, i5.Store, i12.Translations, i13.Toast], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "title-secondary-details-panel", [], null, null, null, i14.View_TitleSecondaryDetailsPanelComponent_0, i14.RenderType_TitleSecondaryDetailsPanelComponent)), i1.ɵdid(4, 573440, null, 0, i15.TitleSecondaryDetailsPanelComponent, [i5.Store], { item: [0, "item"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EpisodePageComponent_2)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "title-cast-panel", [], null, null, null, i17.View_TitleCastPanelComponent_0, i17.RenderType_TitleCastPanelComponent)), i1.ɵdid(8, 49152, null, 0, i18.TitleCastPanelComponent, [i10.TitleUrlsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.settings.get("titles.show_videos_panel"); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_EpisodePageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "media-item-header", [], [[4, "background-image", null], [2, "no-backdrop", null]], null, null, i19.View_MediaItemHeaderComponent_0, i19.RenderType_MediaItemHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i20.MediaItemHeaderComponent, [], { backdrop: [0, "backdrop"] }, null), i1.ɵpid(131072, i16.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ad-host", [["class", "shared-ad-host"], ["slot", "ads.shared"]], [[8, "id", 0]], null, null, i21.View_AdHostComponent_0, i21.RenderType_AdHostComponent)), i1.ɵdid(4, 245760, null, 0, i22.AdHostComponent, [i1.ElementRef, i1.Renderer2, i11.Settings, i7.CurrentUser, i23.LazyLoaderService], { slot: [0, "slot"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EpisodePageComponent_1)), i1.ɵdid(6, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i16.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "footer", [], null, null, null, i24.View_FooterComponent_0, i24.RenderType_FooterComponent)), i1.ɵdid(9, 49152, null, 0, i25.FooterComponent, [i11.Settings], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.backdropImage$)); _ck(_v, 1, 0, currVal_2); var currVal_4 = "ads.shared"; _ck(_v, 4, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.episode$)); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).backgroundImage; var currVal_1 = i1.ɵnov(_v, 1).noBackdrop; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).randomId; _ck(_v, 3, 0, currVal_3); }); }
export function View_EpisodePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "episode-page", [], null, null, null, View_EpisodePageComponent_0, RenderType_EpisodePageComponent)), i1.ɵdid(1, 114688, null, 0, i26.EpisodePageComponent, [i5.Store, i11.Settings], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EpisodePageComponentNgFactory = i1.ɵccf("episode-page", i26.EpisodePageComponent, View_EpisodePageComponent_Host_0, {}, {}, []);
export { EpisodePageComponentNgFactory as EpisodePageComponentNgFactory };
