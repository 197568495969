import * as tslib_1 from "tslib";
import { OnChanges, SimpleChange } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '../../state/title-state';
import { Observable } from 'rxjs';
import { PlayVideo } from '../../../player/state/player-state-actions';
import { MEDIA_TYPE } from '../../../media-type';
var TitleSecondaryDetailsPanelComponent = /** @class */ (function () {
    function TitleSecondaryDetailsPanelComponent(store) {
        this.store = store;
    }
    TitleSecondaryDetailsPanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes.item.currentValue && changes.item.currentValue.credits) {
            this.setCrew();
        }
    };
    TitleSecondaryDetailsPanelComponent.prototype.playVideo = function (video) {
        var title = this.store.selectSnapshot(TitleState.title);
        this.store.dispatch(new PlayVideo(video, title));
    };
    TitleSecondaryDetailsPanelComponent.prototype.isSeries = function () {
        return this.item.type === MEDIA_TYPE.TITLE && this.item.is_series;
    };
    TitleSecondaryDetailsPanelComponent.prototype.setCrew = function () {
        var credits = this.store.selectSnapshot(TitleState.titleOrEpisodeCredits);
        this.credits = {
            director: this.getDirector(credits),
            writers: this.getWriters(credits),
            cast: this.getCast(credits),
            creators: this.getCreators(credits),
        };
    };
    TitleSecondaryDetailsPanelComponent.prototype.getDirector = function (credits) {
        return credits.find(function (person) { return person.pivot.department === 'directing'; });
    };
    TitleSecondaryDetailsPanelComponent.prototype.getWriters = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'writing'; });
    };
    TitleSecondaryDetailsPanelComponent.prototype.getCast = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'cast'; }).slice(0, 3);
    };
    TitleSecondaryDetailsPanelComponent.prototype.getCreators = function (credits) {
        return credits.filter(function (person) { return person.pivot.department === 'creators'; });
    };
    tslib_1.__decorate([
        Select(TitleState.title),
        tslib_1.__metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "title$", void 0);
    tslib_1.__decorate([
        Select(TitleState.videoCoverImage),
        tslib_1.__metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "videoCoverImage$", void 0);
    tslib_1.__decorate([
        Select(TitleState.trailer),
        tslib_1.__metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "trailer$", void 0);
    tslib_1.__decorate([
        Select(TitleState.seasons),
        tslib_1.__metadata("design:type", Observable)
    ], TitleSecondaryDetailsPanelComponent.prototype, "seasons$", void 0);
    return TitleSecondaryDetailsPanelComponent;
}());
export { TitleSecondaryDetailsPanelComponent };
