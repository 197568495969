import { NavigationCancel, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ToggleGlobalLoader } from '../../state/app-state-actions';
import { stringsMatch } from '../../../common/core/utils/strings-match';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngxs/store";
var GlobalLoaderHandlerService = /** @class */ (function () {
    function GlobalLoaderHandlerService(router, store) {
        this.router = router;
        this.store = store;
        this.triggerPatterns = [
            '/titles/*',
            '/titles/*/full-credits',
            '/titles/*/season/*',
            '/titles/*/season/*/episode/*',
            '/people/*',
            '/search?query=*',
        ];
        this.init();
    }
    GlobalLoaderHandlerService.prototype.init = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (e) { return e instanceof NavigationStart || e instanceof NavigationCancel; }))
            .subscribe(function (e) {
            if (e instanceof NavigationStart) {
                var matched = _this.triggerPatterns.some(function (pattern) {
                    return stringsMatch(pattern, e.url);
                });
                if (matched) {
                    _this.store.dispatch(new ToggleGlobalLoader(true));
                }
                else {
                    _this.store.dispatch(new ToggleGlobalLoader(false));
                }
            }
            else {
                _this.store.dispatch(new ToggleGlobalLoader(false));
            }
        });
    };
    GlobalLoaderHandlerService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalLoaderHandlerService_Factory() { return new GlobalLoaderHandlerService(i0.inject(i1.Router), i0.inject(i2.Store)); }, token: GlobalLoaderHandlerService, providedIn: "root" });
    return GlobalLoaderHandlerService;
}());
export { GlobalLoaderHandlerService };
