import { AppHttpClient } from '../../../../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../common/core/http/app-http-client.service";
var SeasonService = /** @class */ (function () {
    function SeasonService(http) {
        this.http = http;
    }
    SeasonService.prototype.create = function (titleId) {
        return this.http.post('titles/' + titleId + '/seasons');
    };
    SeasonService.prototype.delete = function (id) {
        return this.http.delete('seasons/' + id);
    };
    SeasonService.ngInjectableDef = i0.defineInjectable({ factory: function SeasonService_Factory() { return new SeasonService(i0.inject(i1.AppHttpClient)); }, token: SeasonService, providedIn: "root" });
    return SeasonService;
}());
export { SeasonService };
