/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-gallery-overlay.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../media-image/media-image.component.ngfactory";
import * as i3 from "../media-image/media-image.component";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "./image-gallery-overlay.component";
import * as i13 from "../../../../common/core/ui/overlay-panel/overlay-panel-data";
import * as i14 from "../../../../common/core/ui/overlay-panel/overlay-panel-ref";
var styles_ImageGalleryOverlayComponent = [i0.styles];
var RenderType_ImageGalleryOverlayComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ImageGalleryOverlayComponent, data: {} });
export { RenderType_ImageGalleryOverlayComponent as RenderType_ImageGalleryOverlayComponent };
export function View_ImageGalleryOverlayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-image", [["size", "original"]], null, null, null, i2.View_MediaImageComponent_0, i2.RenderType_MediaImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.MediaImageComponent, [i4.Settings], { size: [0, "size"], src: [1, "src"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["class", "close-button"], ["mat-mini-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "original"; var currVal_1 = _co.data.currentImage.url; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = "close"; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 3).disabled || null); var currVal_3 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 5).inline; var currVal_5 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_4, currVal_5); }); }
export function View_ImageGalleryOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-gallery-overlay", [], null, null, null, View_ImageGalleryOverlayComponent_0, RenderType_ImageGalleryOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i12.ImageGalleryOverlayComponent, [[2, i13.OVERLAY_PANEL_DATA], i14.OverlayPanelRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageGalleryOverlayComponentNgFactory = i1.ɵccf("image-gallery-overlay", i12.ImageGalleryOverlayComponent, View_ImageGalleryOverlayComponent_Host_0, {}, {}, []);
export { ImageGalleryOverlayComponentNgFactory as ImageGalleryOverlayComponentNgFactory };
