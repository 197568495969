import { Store } from '@ngxs/store';
import { EMPTY, of } from 'rxjs';
import { LoadLists } from './state/homepage-state.actions';
import { ToggleGlobalLoader } from '../../state/app-state-actions';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var HomepageListsResolverService = /** @class */ (function () {
    function HomepageListsResolverService(store) {
        this.store = store;
    }
    HomepageListsResolverService.prototype.resolve = function (route) {
        return this.store.dispatch([
            new LoadLists(),
            new ToggleGlobalLoader(true),
        ]).pipe(catchError(function () {
            return EMPTY;
        }), mergeMap(function (states) {
            return of({ seo: states[0].homepage.metaTags });
        }));
    };
    HomepageListsResolverService.ngInjectableDef = i0.defineInjectable({ factory: function HomepageListsResolverService_Factory() { return new HomepageListsResolverService(i0.inject(i1.Store)); }, token: HomepageListsResolverService, providedIn: "root" });
    return HomepageListsResolverService;
}());
export { HomepageListsResolverService };
