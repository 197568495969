import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var HomepageService = /** @class */ (function () {
    function HomepageService(http) {
        this.http = http;
    }
    HomepageService.prototype.getLists = function () {
        return this.http.get('homepage/lists');
    };
    HomepageService.ngInjectableDef = i0.defineInjectable({ factory: function HomepageService_Factory() { return new HomepageService(i0.inject(i1.AppHttpClient)); }, token: HomepageService, providedIn: "root" });
    return HomepageService;
}());
export { HomepageService };
