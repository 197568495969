import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { AttachItem, CreateOrUpdateList, DeleteList, DetachItem, ReloadList, ReorderList, ResetState, UpdateDetails } from './list-actions';
import { ListsService } from '../lists.service';
import { finalize, tap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { UserListsState } from '../user-lists/state/user-lists-state';
import { Toast } from '../../../../common/core/ui/toast.service';
import { MESSAGES } from '../../../toast-messages';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ClearUserLists } from '../user-lists/state/user-lists-state-actions';
var ListState = /** @class */ (function () {
    function ListState(lists, store, toast) {
        this.lists = lists;
        this.store = store;
        this.toast = toast;
    }
    ListState_1 = ListState;
    ListState.items = function (state) {
        return state.items;
    };
    ListState.updating = function (state) {
        return !!state.list.id;
    };
    ListState.list = function (state) {
        return state.list;
    };
    ListState.system = function (state) {
        return state.list.system;
    };
    ListState.public = function (state) {
        return !state.list.system && state.list.public;
    };
    ListState.loading = function (state) {
        return state.loading;
    };
    ListState.params = function (state) {
        return state.params;
    };
    ListState.totalCount = function (state) {
        return state.pagination.total || 0;
    };
    ListState.currentCount = function (state) {
        return state.items.length;
    };
    ListState.prototype.attachItem = function (ctx, action) {
        var _this = this;
        var listId = ctx.getState().list.id;
        var newItem = action.item;
        var alreadyContains = ctx.getState().items
            .find(function (item) { return item.id === newItem.id && item.type === newItem.type; });
        if (alreadyContains)
            return;
        // list is not created yet
        if (!ctx.getState().list.id) {
            return this.addItem(ctx, action.item);
        }
        ctx.patchState({ loading: true });
        return this.lists.addItem(listId, action.item).pipe(tap(function () {
            _this.toast.open(MESSAGES.LIST_ITEM_ADD_SUCCESS);
            _this.addItem(ctx, action.item);
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.detachItem = function (ctx, action) {
        var _this = this;
        var listId = ctx.getState().list.id;
        // list is not created yet
        if (!listId) {
            return this.removeItem(ctx, action.item);
        }
        ctx.patchState({ loading: true });
        return this.lists.removeItem(listId, action.item).pipe(tap(function () {
            _this.toast.open(MESSAGES.LIST_ITEM_REMOVE_SUCCESS);
            _this.removeItem(ctx, action.item);
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.updateDetails = function (ctx, action) {
        ctx.patchState({
            list: tslib_1.__assign({}, ctx.getState().list, action.details),
        });
    };
    ListState.prototype.createOrUpdateList = function (ctx) {
        var _this = this;
        ctx.patchState({ loading: true });
        var payload = this.getPayload(ctx);
        var request;
        if (ctx.getState().list.id) {
            request = this.lists.update(ctx.getState().list.id, payload);
        }
        else {
            request = this.lists.create(payload);
        }
        return request.pipe(tap(function (response) {
            var msg = ctx.getState().list.id ? MESSAGES.LIST_UPDATE_SUCCESS : MESSAGES.LIST_CREATE_SUCCESS;
            ctx.patchState({ list: response.list });
            _this.store.dispatch(new ClearUserLists());
            ctx.dispatch(new Navigate(_this.getListRoute()));
            _this.toast.open(msg);
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.reloadList = function (ctx, action) {
        var _this = this;
        if (!action.id)
            return;
        ctx.patchState({ loading: true });
        if (action.params) {
            ctx.patchState({ params: tslib_1.__assign({}, ctx.getState().params, action.params) });
        }
        return this.lists.get(action.id, ctx.getState().params).pipe(tap(function (response) {
            ctx.patchState({
                list: response.list,
                items: response.items.data,
                pagination: tslib_1.__assign({}, response.items, { data: [] }),
            });
        }, function () {
            _this.store.dispatch(new Navigate(['/lists']));
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.reorderList = function (ctx, action) {
        var items = ctx.getState().items.slice();
        moveItemInArray(items, action.currentIndex, action.newIndex);
        ctx.patchState({ items: items });
        // list is not created yet
        if (!ctx.getState().list.id)
            return;
        var order = {};
        items.forEach(function (item, index) {
            order[index] = item.pivot.id;
        });
        ctx.patchState({ loading: true });
        return this.lists.reorder(ctx.getState().list.id, order).pipe(finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.deleteList = function (ctx, action) {
        var _this = this;
        ctx.patchState({ loading: true });
        return this.lists.delete([action.id]).pipe(tap(function () {
            _this.store.dispatch([
                new ClearUserLists(),
                new ResetState()
            ]);
        }), finalize(function () { return ctx.patchState({ loading: false }); }));
    };
    ListState.prototype.resetState = function (ctx) {
        ctx.patchState({
            loading: false,
            list: {},
            items: [],
            pagination: {},
            params: {
                sortBy: 'pivot.order',
                sortDir: 'asc',
            }
        });
    };
    ListState.prototype.addItem = function (ctx, item) {
        ctx.patchState({
            items: ctx.getState().items.concat([item])
        });
    };
    ListState.prototype.removeItem = function (ctx, item) {
        var newItems = ctx.getState().items.filter(function (curr) {
            return curr.id !== item.id;
        });
        ctx.patchState({ items: newItems });
    };
    ListState.prototype.getPayload = function (ctx) {
        return {
            details: ctx.getState().list,
            items: ctx.getState().items.map(function (item) {
                return {
                    id: item.id,
                    type: item.type
                };
            })
        };
    };
    ListState.prototype.getListRoute = function () {
        var listId = this.store.selectSnapshot(ListState_1.list).id, watchlistId = this.store.selectSnapshot(UserListsState.watchlist).id;
        if (listId === watchlistId) {
            return ['/watchlist'];
        }
        else {
            return ['/lists', listId];
        }
    };
    var ListState_1;
    tslib_1.__decorate([
        Action(AttachItem),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AttachItem]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "attachItem", null);
    tslib_1.__decorate([
        Action(DetachItem),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DetachItem]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "detachItem", null);
    tslib_1.__decorate([
        Action(UpdateDetails),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UpdateDetails]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "updateDetails", null);
    tslib_1.__decorate([
        Action(CreateOrUpdateList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "createOrUpdateList", null);
    tslib_1.__decorate([
        Action(ReloadList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ReloadList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "reloadList", null);
    tslib_1.__decorate([
        Action(ReorderList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ReorderList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "reorderList", null);
    tslib_1.__decorate([
        Action(DeleteList),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, DeleteList]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "deleteList", null);
    tslib_1.__decorate([
        Action(ResetState),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState.prototype, "resetState", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "items", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "updating", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "list", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "system", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "public", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "loading", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "params", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "totalCount", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ListState, "currentCount", null);
    ListState = ListState_1 = tslib_1.__decorate([
        State({
            name: 'list',
            defaults: {
                loading: false,
                list: {},
                items: [],
                pagination: {},
                params: {
                    sortBy: 'pivot.order',
                    sortDir: 'asc',
                }
            }
        }),
        tslib_1.__metadata("design:paramtypes", [ListsService,
            Store,
            Toast])
    ], ListState);
    return ListState;
}());
export { ListState };
