export const TITLE_SORT_OPTIONS = [
    'popularity',
    'user_score',
    'release_date',
    'budget',
    'revenue',
    'runtime',
    'language',
    'date_added',
    'certification',
];
