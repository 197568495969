import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadRelatedVideos, PlayerOverlayClosed, PlayerOverlayOpened, PlayVideo, ToggleSidebar } from './player-state-actions';
import { TitlesService } from '../../titles/titles.service';
var PlayerState = /** @class */ (function () {
    function PlayerState(titles) {
        this.titles = titles;
    }
    PlayerState.isOpen = function (state) {
        return state.isOpen;
    };
    PlayerState.activeVideo = function (state) {
        return state.activeVideo;
    };
    PlayerState.relatedVideos = function (state) {
        return state.relatedVideos;
    };
    PlayerState.mediaItem = function (state) {
        return state.mediaItem;
    };
    PlayerState.sidebarOpen = function (state) {
        return state.sidebarOpen;
    };
    PlayerState.prototype.playVideo = function (ctx, action) {
        var state = ctx.getState();
        // already have this video and title loaded
        if (state.activeVideo && state.activeVideo.id === action.video.id) {
            return;
        }
        ctx.patchState({ activeVideo: action.video, mediaItem: action.mediaItem });
        ctx.dispatch(new LoadRelatedVideos());
    };
    PlayerState.prototype.loadRelatedVideos = function (ctx) {
        var mediaItem = ctx.getState().mediaItem;
        return this.titles.getRelatedVideos(mediaItem['title_id'] || mediaItem.id, ctx.getState().activeVideo.id).pipe(tap(function (response) {
            ctx.patchState({ relatedVideos: response.videos });
        }));
    };
    PlayerState.prototype.playerOverlayClosed = function (ctx) {
        return ctx.patchState({ isOpen: false });
    };
    PlayerState.prototype.playerOverlayOpened = function (ctx) {
        return ctx.patchState({ isOpen: true });
    };
    PlayerState.prototype.toggleSidebar = function (ctx, action) {
        var state = action.open === null ? !ctx.getState().sidebarOpen : action.open;
        return ctx.patchState({ sidebarOpen: state });
    };
    tslib_1.__decorate([
        Action(PlayVideo),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, PlayVideo]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playVideo", null);
    tslib_1.__decorate([
        Action(LoadRelatedVideos),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "loadRelatedVideos", null);
    tslib_1.__decorate([
        Action(PlayerOverlayClosed),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayClosed", null);
    tslib_1.__decorate([
        Action(PlayerOverlayOpened),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "playerOverlayOpened", null);
    tslib_1.__decorate([
        Action(ToggleSidebar),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ToggleSidebar]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState.prototype, "toggleSidebar", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "isOpen", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "activeVideo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "relatedVideos", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "mediaItem", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PlayerState, "sidebarOpen", null);
    PlayerState = tslib_1.__decorate([
        State({
            name: 'player',
            defaults: {
                relatedVideos: [],
                isOpen: false,
                sidebarOpen: true,
            }
        }),
        tslib_1.__metadata("design:paramtypes", [TitlesService])
    ], PlayerState);
    return PlayerState;
}());
export { PlayerState };
