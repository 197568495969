import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { User } from '../core/types/models/User';
import * as i0 from "@angular/core";
var CurrentUser = /** @class */ (function () {
    function CurrentUser() {
        this.userChanged = new EventEmitter();
    }
    /**
     * Get property of currently logged in user model.
     */
    CurrentUser.prototype.get = function (prop) {
        return this.current && this.current[prop];
    };
    /**
     * Get model of currently logged in user.
     */
    CurrentUser.prototype.getModel = function () {
        return Object.assign({}, this.current);
    };
    /**
     * Set property of currently logged in user object.
     */
    CurrentUser.prototype.set = function (key, value) {
        this.current[key] = value;
    };
    /**
     * Set a new current user.
     */
    CurrentUser.prototype.assignCurrent = function (model) {
        this.clear();
        if (model) {
            this.current = model;
        }
        this.userChanged.emit(this.current);
    };
    /**
     * Check if current user has all specified permissions.
     */
    CurrentUser.prototype.hasPermissions = function (permissions) {
        var _this = this;
        return permissions.filter(function (permission) {
            return !_this.hasPermission(permission);
        }).length === 0;
    };
    /**
     * Check if user has given permission.
     */
    CurrentUser.prototype.hasPermission = function (permission) {
        var permissions = this.getAllPermissions();
        return (permissions['admin'] || permissions[permission]) > 0;
    };
    CurrentUser.prototype.hasRole = function (role) {
        return this.current.roles && !!this.current.roles.find(function (r) { return r.name === role; });
    };
    /**
     * Check if current user is logged in.
     */
    CurrentUser.prototype.isLoggedIn = function () {
        return this.get('id') > 0;
    };
    /**
     * Check if user subscription is active, on trial, or on grace period.
     */
    CurrentUser.prototype.isSubscribed = function () {
        if (!this.current.subscriptions)
            return false;
        return this.current.subscriptions.find(function (sub) { return sub.valid; }) !== undefined;
    };
    /**
     * Check if user subscription is active
     */
    CurrentUser.prototype.subscriptionIsActive = function () {
        return this.isSubscribed() && !this.onTrial();
    };
    CurrentUser.prototype.onTrial = function () {
        var sub = this.getSubscription();
        return sub && sub.on_trial;
    };
    CurrentUser.prototype.onGracePeriod = function () {
        var sub = this.getSubscription();
        return sub && sub.on_grace_period;
    };
    CurrentUser.prototype.getSubscription = function (filters) {
        if (filters === void 0) { filters = {}; }
        if (!this.isSubscribed())
            return null;
        var subs = this.current.subscriptions.slice();
        if (filters.gateway) {
            subs = subs.filter(function (sub) { return sub.gateway === filters.gateway; });
        }
        if (filters.planId) {
            subs = subs.filter(function (sub) { return sub.plan_id === filters.planId; });
        }
        return subs[0];
    };
    /**
     * Set specified subscription on current user model.
     */
    CurrentUser.prototype.setSubscription = function (subscription) {
        var i = this.current.subscriptions.findIndex(function (sub) { return sub.id === subscription.id; });
        if (i > -1) {
            this.current.subscriptions[i] = subscription;
        }
        else {
            this.current.subscriptions.push(subscription);
        }
    };
    /**
     * Check if current user is an admin.
     */
    CurrentUser.prototype.isAdmin = function () {
        return this.hasPermission('admin');
    };
    /**
     * Clear current user information.
     */
    CurrentUser.prototype.clear = function () {
        this.current = new User({ roles: [this.guestsRole] });
        this.cachedPermissions = null;
        this.userChanged.emit(this.current);
    };
    /**
     * Init CurrentUser service.
     */
    CurrentUser.prototype.init = function (params) {
        this.guestsRole = params.guestsRole;
        this.assignCurrent(params.user);
    };
    /**
     * Get flattened array of all permissions current user has.
     */
    CurrentUser.prototype.getAllPermissions = function () {
        if (this.cachedPermissions) {
            return this.cachedPermissions;
        }
        // permissions on user modal
        var permissions = tslib_1.__assign({}, this.get('permissions'));
        // merge role permissions
        var roles = this.get('roles') || [];
        roles.forEach(function (role) {
            if (role) {
                permissions = tslib_1.__assign({}, permissions, role.permissions);
            }
        });
        // merge billing plan permissions
        var subscription = this.getSubscription();
        if (subscription && subscription.plan) {
            permissions = tslib_1.__assign({}, permissions, subscription.plan.permissions);
        }
        return this.cachedPermissions = permissions;
    };
    CurrentUser.ngInjectableDef = i0.defineInjectable({ factory: function CurrentUser_Factory() { return new CurrentUser(); }, token: CurrentUser, providedIn: "root" });
    return CurrentUser;
}());
export { CurrentUser };
