import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var VideoService = /** @class */ (function () {
    function VideoService(http) {
        this.http = http;
    }
    VideoService.prototype.create = function (payload) {
        return this.http.post('videos', payload);
    };
    VideoService.prototype.update = function (id, payload) {
        return this.http.put('videos/' + id, payload);
    };
    VideoService.prototype.delete = function (ids) {
        return this.http.delete('videos', { ids: ids });
    };
    VideoService.prototype.rate = function (id, rating) {
        return this.http.post('videos/' + id + '/rate', { rating: rating });
    };
    VideoService.ngInjectableDef = i0.defineInjectable({ factory: function VideoService_Factory() { return new VideoService(i0.inject(i1.AppHttpClient)); }, token: VideoService, providedIn: "root" });
    return VideoService;
}());
export { VideoService };
