/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../ui/material-navbar/material-navbar.component.ngfactory";
import * as i3 from "../../ui/material-navbar/material-navbar.component";
import * as i4 from "../../config/settings.service";
import * as i5 from "../../../auth/current-user";
import * as i6 from "../../ui/breakpoints.service";
import * as i7 from "@angular/common";
import * as i8 from "../pages.service";
import * as i9 from "../../http/http-cache-client";
import * as i10 from "./custom-page.component";
import * as i11 from "@angular/router";
import * as i12 from "@angular/platform-browser";
var styles_CustomPageComponent = [i0.styles];
var RenderType_CustomPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CustomPageComponent, data: {} });
export { RenderType_CustomPageComponent as RenderType_CustomPageComponent };
function View_CustomPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "material-navbar", [["menuPosition", "custom-page-navbar"]], [[2, "transparent", null]], null, null, i2.View_MaterialNavbar_0, i2.RenderType_MaterialNavbar)), i1.ɵdid(1, 114688, null, 0, i3.MaterialNavbar, [i4.Settings, i5.CurrentUser, i1.ElementRef, i6.BreakpointsService], { menuPosition: [0, "menuPosition"] }, null)], function (_ck, _v) { var currVal_1 = "custom-page-navbar"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).transparent; _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "page-body container article-body"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.settings.get("vebto.customPages.hideNavbar"); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.body; _ck(_v, 2, 0, currVal_1); }); }
export function View_CustomPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "custom-page", [], null, null, null, View_CustomPageComponent_0, RenderType_CustomPageComponent)), i1.ɵprd(512, null, i8.Pages, i8.Pages, [i9.HttpCacheClient]), i1.ɵdid(2, 114688, null, 0, i10.CustomPageComponent, [i8.Pages, i11.ActivatedRoute, i12.DomSanitizer, i11.Router, i4.Settings], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomPageComponentNgFactory = i1.ɵccf("custom-page", i10.CustomPageComponent, View_CustomPageComponent_Host_0, {}, {}, []);
export { CustomPageComponentNgFactory as CustomPageComponentNgFactory };
