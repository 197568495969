/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../translations/translate.directive";
import * as i3 from "../../translations/translations.service";
import * as i4 from "../../config/settings.service";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./not-found-page.component";
var styles_NotFoundPageComponent = [i0.styles];
var RenderType_NotFoundPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NotFoundPageComponent, data: {} });
export { RenderType_NotFoundPageComponent as RenderType_NotFoundPageComponent };
export function View_NotFoundPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["Opps! We can't find the page you're looking for."])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [["color", "primary"], ["mat-raised-button", ""], ["routerLink", "/"], ["trans", ""]], [[1, "target", 0], [8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._haltDisabledEvents($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(5, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(6, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), i1.ɵdid(7, 180224, null, 0, i8.MatAnchor, [i9.Platform, i10.FocusMonitor, i1.ElementRef, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Home"]))], function (_ck, _v) { var currVal_7 = "/"; _ck(_v, 5, 0, currVal_7); var currVal_8 = "primary"; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.getAssetUrl("images/404.png"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; var currVal_3 = (i1.ɵnov(_v, 7).disabled ? (0 - 1) : (i1.ɵnov(_v, 7).tabIndex || 0)); var currVal_4 = (i1.ɵnov(_v, 7).disabled || null); var currVal_5 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_6 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NotFoundPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "not-found-page", [], null, null, null, View_NotFoundPageComponent_0, RenderType_NotFoundPageComponent)), i1.ɵdid(1, 49152, null, 0, i12.NotFoundPageComponent, [i4.Settings], null, null)], null, null); }
var NotFoundPageComponentNgFactory = i1.ɵccf("not-found-page", i12.NotFoundPageComponent, View_NotFoundPageComponent_Host_0, {}, {}, []);
export { NotFoundPageComponentNgFactory as NotFoundPageComponentNgFactory };
