var MediaItemHeaderComponent = /** @class */ (function () {
    function MediaItemHeaderComponent() {
        this.transparent = false;
    }
    Object.defineProperty(MediaItemHeaderComponent.prototype, "backgroundImage", {
        get: function () {
            if (this.backdrop) {
                return 'url(' + this.backdrop + ')';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MediaItemHeaderComponent.prototype, "noBackdrop", {
        get: function () {
            if (!this.backdrop) {
                return 'no-backdrop';
            }
        },
        enumerable: true,
        configurable: true
    });
    return MediaItemHeaderComponent;
}());
export { MediaItemHeaderComponent };
