/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./range-slider.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../common/core/translations/translate.directive";
import * as i4 from "../../../../common/core/translations/translations.service";
import * as i5 from "../../../../common/core/config/settings.service";
import * as i6 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../../../common/core/translations/translate.pipe";
import * as i9 from "@angular/forms";
import * as i10 from "./range-slider.component";
var styles_RangeSliderComponent = [i0.styles];
var RenderType_RangeSliderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RangeSliderComponent, data: {} });
export { RenderType_RangeSliderComponent as RenderType_RangeSliderComponent };
function View_RangeSliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.affix)); _ck(_v, 1, 0, currVal_0); }); }
function View_RangeSliderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.affix)); _ck(_v, 1, 0, currVal_0); }); }
function View_RangeSliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RangeSliderComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.affix; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf[1]; _ck(_v, 1, 0, currVal_0); }); }
function View_RangeSliderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["trans", ""]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["No limit"]))], null, null); }
function View_RangeSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RangeSliderComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon"], ["role", "img"], ["svgIcon", "compare-arrows"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RangeSliderComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLimit", 2]], null, 0, null, View_RangeSliderComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.affix; _ck(_v, 4, 0, currVal_1); var currVal_4 = "compare-arrows"; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.context.ngIf[1]; var currVal_6 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf[0]; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).inline; var currVal_3 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_2, currVal_3); }); }
export function View_RangeSliderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i8.TransPipe, [i4.Translations]), i1.ɵqud(402653184, 1, { sliderEl: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["sliderEl", 1]], null, 0, "div", [["class", "slider-el"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "values"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RangeSliderComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.value$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_RangeSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "range-slider", [], null, null, null, View_RangeSliderComponent_0, RenderType_RangeSliderComponent)), i1.ɵprd(5120, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i10.RangeSliderComponent]), i1.ɵdid(2, 245760, null, 0, i10.RangeSliderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RangeSliderComponentNgFactory = i1.ɵccf("range-slider", i10.RangeSliderComponent, View_RangeSliderComponent_Host_0, { min: "min", max: "max", step: "step", float: "float", affix: "affix" }, {}, []);
export { RangeSliderComponentNgFactory as RangeSliderComponentNgFactory };
