/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../titles/components/genre-widget/genre-widget.component.ngfactory";
import * as i3 from "../../titles/components/genre-widget/genre-widget.component";
import * as i4 from "../media-image/media-image.component.ngfactory";
import * as i5 from "../media-image/media-image.component";
import * as i6 from "../../../../common/core/config/settings.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./media-grid.component";
import * as i10 from "../../titles/title-urls.service";
var styles_MediaGridComponent = [i0.styles];
var RenderType_MediaGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MediaGridComponent, data: {} });
export { RenderType_MediaGridComponent as RenderType_MediaGridComponent };
function View_MediaGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "genre-widget", [], null, null, null, i2.View_GenreWidgetComponent_0, i2.RenderType_GenreWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i3.GenreWidgetComponent, [], { genres: [0, "genres"], limit: [1, "limit"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.genres; var currVal_1 = 3; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MediaGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "figure", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "media-image", [["size", "medium"]], null, null, null, i4.View_MediaImageComponent_0, i4.RenderType_MediaImageComponent)), i1.ɵdid(2, 49152, null, 0, i5.MediaImageComponent, [i6.Settings], { link: [0, "link"], size: [1, "size"], src: [2, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "figcaption", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "title"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls.mediaItem(_v.context.$implicit); var currVal_1 = "medium"; var currVal_2 = _v.context.$implicit.poster; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 5, 0, currVal_5); var currVal_7 = !_co.isPerson(_v.context.$implicit); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 5).target; var currVal_4 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_6); }); }
export function View_MediaGridComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaGridComponent_1)), i1.ɵdid(1, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MediaGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "media-grid", [], null, null, null, View_MediaGridComponent_0, RenderType_MediaGridComponent)), i1.ɵdid(1, 49152, null, 0, i9.MediaGridComponent, [i10.TitleUrlsService], null, null)], null, null); }
var MediaGridComponentNgFactory = i1.ɵccf("media-grid", i9.MediaGridComponent, View_MediaGridComponent_Host_0, { actionIcon: "actionIcon", items: "items" }, { actionClick: "actionClick" }, []);
export { MediaGridComponentNgFactory as MediaGridComponentNgFactory };
