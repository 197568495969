import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var ImagesService = /** @class */ (function () {
    function ImagesService(http) {
        this.http = http;
    }
    ImagesService.prototype.create = function (file, params) {
        var payload = new FormData();
        payload.append('file', file.native);
        Object.keys(params).forEach(function (key) {
            payload.append(key, params[key]);
        });
        return this.http.post('images', payload);
    };
    ImagesService.prototype.delete = function (id) {
        return this.http.delete('images', { id: id });
    };
    ImagesService.ngInjectableDef = i0.defineInjectable({ factory: function ImagesService_Factory() { return new ImagesService(i0.inject(i1.AppHttpClient)); }, token: ImagesService, providedIn: "root" });
    return ImagesService;
}());
export { ImagesService };
