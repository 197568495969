import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SearchService } from '../search.service';
import { Router } from '@angular/router';
import { TitlesService } from '../../titles/titles.service';
import { Select, Store } from '@ngxs/store';
import { Reset, SearchEverything } from '../state/search-state-actions';
import { SearchState } from '../state/search-state';
import { ToggleGlobalLoader } from '../../../state/app-state-actions';
import { TitleUrlsService } from '../../titles/title-urls.service';
import { MEDIA_TYPE } from '../../media-type';
import { PeopleService } from '../../people/people.service';
import { SetPerson } from '../../people/state/person-state-actions';
import { SetTitle } from '../../titles/state/title-actions';
var SearchInputComponent = /** @class */ (function () {
    function SearchInputComponent(search, router, titles, people, store, urls) {
        this.search = search;
        this.router = router;
        this.titles = titles;
        this.people = people;
        this.store = store;
        this.urls = urls;
        this.resultSelected = new EventEmitter();
        this.placeholder = 'Search for movies, tv shows and people...';
        this.resetInputOnSelect = true;
        this.searchControl = new FormControl();
    }
    SearchInputComponent.prototype.ngOnInit = function () {
        this.bindToSearchQueryControl();
    };
    SearchInputComponent.prototype.bindToSearchQueryControl = function () {
        var _this = this;
        this.searchControl.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), filter(function (query) { return typeof query === 'string'; }), switchMap(function (query) { return _this.store.dispatch(new SearchEverything(query, _this.type, 8)); }), catchError(function () { return of({ results: [] }); })).subscribe();
    };
    SearchInputComponent.prototype.selectResult = function (e) {
        var _this = this;
        this.reset();
        var shouldNavigate = !this.resultSelected.observers.length, result = e.option.value;
        if (shouldNavigate) {
            this.store.dispatch(new ToggleGlobalLoader(true));
        }
        this.loadResult(result).subscribe(function (response) {
            var mediaItem = response['title'] || response['person'];
            if (shouldNavigate) {
                _this.openMediaItemPage(response, mediaItem);
            }
            else {
                _this.resultSelected.emit(mediaItem);
            }
        }, function () {
            _this.store.dispatch(new ToggleGlobalLoader(false));
        });
    };
    SearchInputComponent.prototype.openMediaItemPage = function (response, mediaItem) {
        if (mediaItem.type === MEDIA_TYPE.PERSON) {
            this.store.dispatch(new SetPerson(response));
        }
        else {
            var titleResponse = response;
            this.store.dispatch(new SetTitle(titleResponse, { titleId: titleResponse.title.id }));
        }
        this.router.navigate(this.urls.mediaItem(mediaItem));
    };
    SearchInputComponent.prototype.loadResult = function (result) {
        if (result.type === MEDIA_TYPE.PERSON) {
            return this.people.get(result.id)
                .pipe(map(function (response) { return response; }));
        }
        else {
            return this.titles.get(result.id)
                .pipe(map(function (response) { return response; }));
        }
    };
    SearchInputComponent.prototype.reset = function () {
        this.inputEl.nativeElement.blur();
        this.store.dispatch(new Reset());
        if (this.resetInputOnSelect) {
            this.searchControl.reset();
        }
    };
    SearchInputComponent.prototype.openSearchPage = function () {
        this.router.navigate(['search'], { queryParams: { query: this.searchControl.value } });
    };
    SearchInputComponent.prototype.isPerson = function (result) {
        return result.type === MEDIA_TYPE.PERSON;
    };
    SearchInputComponent.prototype.displayFn = function (result) {
        return result ? result.name : null;
    };
    tslib_1.__decorate([
        Select(SearchState.results),
        tslib_1.__metadata("design:type", Observable)
    ], SearchInputComponent.prototype, "results$", void 0);
    return SearchInputComponent;
}());
export { SearchInputComponent };
