/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./related-titles-panel.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/core/translations/translate.directive";
import * as i3 from "../../../../../common/core/translations/translations.service";
import * as i4 from "../../../../../common/core/config/settings.service";
import * as i5 from "../../../shared/media-grid/media-grid.component.ngfactory";
import * as i6 from "../../../shared/media-grid/media-grid.component";
import * as i7 from "../../title-urls.service";
import * as i8 from "@angular/common";
import * as i9 from "./related-titles-panel.component";
import * as i10 from "@ngxs/store";
import * as i11 from "@angular/router";
var styles_RelatedTitlesPanelComponent = [i0.styles];
var RenderType_RelatedTitlesPanelComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_RelatedTitlesPanelComponent, data: {} });
export { RenderType_RelatedTitlesPanelComponent as RenderType_RelatedTitlesPanelComponent };
export function View_RelatedTitlesPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header"], ["trans", ""]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i2.TranslateDirective, [i1.ElementRef, i3.Translations, i4.Settings], null, null), (_l()(), i1.ɵted(-1, null, ["More Like This..."])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "titles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "media-grid", [["class", "full-width small-titles"]], null, null, null, i5.View_MediaGridComponent_0, i5.RenderType_MediaGridComponent)), i1.ɵdid(6, 49152, null, 0, i6.MediaGridComponent, [i7.TitleUrlsService], { items: [0, "items"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.related$)); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_RelatedTitlesPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "related-titles-panel", [], [[2, "hidden", null]], null, null, View_RelatedTitlesPanelComponent_0, RenderType_RelatedTitlesPanelComponent)), i1.ɵdid(1, 114688, null, 0, i9.RelatedTitlesPanelComponent, [i10.Store, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).noRelatedTitles; _ck(_v, 0, 0, currVal_0); }); }
var RelatedTitlesPanelComponentNgFactory = i1.ɵccf("related-titles-panel", i9.RelatedTitlesPanelComponent, View_RelatedTitlesPanelComponent_Host_0, {}, {}, []);
export { RelatedTitlesPanelComponentNgFactory as RelatedTitlesPanelComponentNgFactory };
