import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var TitlesService = /** @class */ (function () {
    function TitlesService(http) {
        this.http = http;
    }
    TitlesService.prototype.get = function (titleId, queryParams) {
        return this.http.get('titles/' + titleId, queryParams);
    };
    TitlesService.prototype.getAll = function (queryParams) {
        return this.http.get('titles', queryParams);
    };
    TitlesService.prototype.getRelatedVideos = function (titleId, videoId) {
        return this.http.get('related-videos', { titleId: titleId, videoId: videoId });
    };
    TitlesService.prototype.getRelatedTitles = function (title, params) {
        return this.http.get('titles/' + title.id + '/related', params);
    };
    TitlesService.prototype.create = function (payload) {
        return this.http.post('titles', payload);
    };
    TitlesService.prototype.update = function (id, payload) {
        return this.http.put('titles/' + id, payload);
    };
    TitlesService.prototype.delete = function (ids) {
        return this.http.delete('titles', { ids: ids });
    };
    TitlesService.prototype.updateCredit = function (id, payload) {
        return this.http.put('titles/credits/' + id, { credit: payload });
    };
    TitlesService.prototype.addCredit = function (personId, creditable, pivot) {
        return this.http.post('titles/credits', { personId: personId, creditable: creditable, pivot: pivot });
    };
    TitlesService.prototype.removeCredit = function (id) {
        return this.http.delete('titles/credits/' + id);
    };
    TitlesService.prototype.changeCreditsOrder = function (payload) {
        return this.http.post('titles/credits/reorder', { ids: payload });
    };
    TitlesService.prototype.getEpisode = function (id) {
        return this.http.get('episodes/' + id);
    };
    TitlesService.prototype.deleteEpisode = function (id) {
        return this.http.delete('episodes/' + id);
    };
    TitlesService.prototype.createEpisode = function (seasonId, payload) {
        return this.http.post('seasons/' + seasonId + '/episodes', payload);
    };
    TitlesService.prototype.updateEpisode = function (id, payload) {
        return this.http.put('episodes/' + id, payload);
    };
    TitlesService.prototype.createTag = function (titleId, params) {
        return this.http.post('titles/' + titleId + '/tags', params);
    };
    TitlesService.prototype.detachTag = function (titleId, tag) {
        return this.http.delete('titles/' + titleId + '/tags/' + tag.type + '/' + tag.id);
    };
    TitlesService.prototype.changeVideosOrder = function (titleId, order) {
        return this.http.post('titles/' + titleId + '/videos/change-order', { ids: order });
    };
    TitlesService.prototype.import = function (params) {
        return this.http.post('media/import', params);
    };
    TitlesService.ngInjectableDef = i0.defineInjectable({ factory: function TitlesService_Factory() { return new TitlesService(i0.inject(i1.AppHttpClient)); }, token: TitlesService, providedIn: "root" });
    return TitlesService;
}());
export { TitlesService };
