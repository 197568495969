import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FormBuilder } from '@angular/forms';
import { LoadFilterOptions, LoadMoreTitles, ReloadTitles } from '../../state/browse/browse-title-actions';
import { BrowseTitleState } from '../../state/browse/browse-title.state';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatSelectionList } from '@angular/material';
import { debounceTime, skip } from 'rxjs/operators';
import { BreakpointsService } from '../../../../../common/core/ui/breakpoints.service';
import { InfiniteScroll } from '../../../../../common/core/ui/infinite-scroll/infinite.scroll';
import { Settings } from '../../../../../common/core/config/settings.service';
var BrowseTitlesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BrowseTitlesComponent, _super);
    function BrowseTitlesComponent(store, fb, route, breakpoints, settings) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.fb = fb;
        _this.route = route;
        _this.breakpoints = breakpoints;
        _this.settings = settings;
        _this.form = _this.fb.group({
            type: [],
            genre: [],
            released: [],
            score: [],
            country: [],
            language: [],
            runtime: [],
            certification: [],
            order: [],
            onlyStreamable: [],
        });
        _this.store.dispatch(new LoadFilterOptions());
        return _this;
    }
    BrowseTitlesComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.queryParams.pipe(skip(1)).subscribe(function (params) {
            if (params.type && params.type !== _this.form.value.type) {
                _this.form.reset({ type: params.type });
            }
        });
        // reload titles when form is updated
        this.formSub = this.form.valueChanges
            .pipe(debounceTime(50))
            .subscribe(function (value) {
            _this.store.dispatch(new ReloadTitles(_this.filterOutNullAndMaxValues(value)));
        });
        var params = BrowseTitleState.queryParamsToFilters(this.route.snapshot.queryParams);
        if (!params.onlyStreamable) {
            params.onlyStreamable = !!this.settings.get('browse.streamable_filter_state', false);
        }
        this.form.patchValue(params);
    };
    BrowseTitlesComponent.prototype.filterOutNullAndMaxValues = function (params) {
        var filtered = {};
        // filters with these values are at maximum range. Rating at
        // (1 to 10) for example so we can remove this filter completely
        var maxValues = ['1,255', '1.0,10.0', '1880,' + this.currentYear()];
        Object.keys(params).forEach(function (key) {
            var value = params[key], isEmpty = Array.isArray(value) && !value.length;
            if (value && !isEmpty && maxValues.indexOf(value) === -1) {
                filtered[key] = value;
            }
        });
        return filtered;
    };
    BrowseTitlesComponent.prototype.ngOnDestroy = function () {
        this.formSub.unsubscribe();
    };
    BrowseTitlesComponent.prototype.clearAllFilters = function () {
        this.form.reset();
    };
    BrowseTitlesComponent.prototype.currentYear = function () {
        return (new Date()).getFullYear();
    };
    BrowseTitlesComponent.prototype.loadMoreItems = function () {
        this.store.dispatch(new LoadMoreTitles());
    };
    BrowseTitlesComponent.prototype.canLoadMore = function () {
        return this.store.selectSnapshot(BrowseTitleState.canLoadMore);
    };
    BrowseTitlesComponent.prototype.isLoading = function () {
        return this.store.selectSnapshot(BrowseTitleState.loading);
    };
    tslib_1.__decorate([
        Select(BrowseTitleState.titles),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "titles$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.doesNotHaveResults),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "doesNotHaveResults$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.loading),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "loading$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.anyFilterActive),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "anyFilterActive$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.countries),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "countries$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.languages),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "languages$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.genres),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "genres$", void 0);
    tslib_1.__decorate([
        Select(BrowseTitleState.certifications),
        tslib_1.__metadata("design:type", Observable)
    ], BrowseTitlesComponent.prototype, "certifications$", void 0);
    return BrowseTitlesComponent;
}(InfiniteScroll));
export { BrowseTitlesComponent };
