/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./homepage.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/core/ui/ad-host/ad-host.component.ngfactory";
import * as i3 from "../../../common/core/ui/ad-host/ad-host.component";
import * as i4 from "../../../common/core/config/settings.service";
import * as i5 from "../../../common/auth/current-user";
import * as i6 from "../../../common/core/utils/lazy-loader.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../shared/media-grid/media-grid.component.ngfactory";
import * as i10 from "../shared/media-grid/media-grid.component";
import * as i11 from "../titles/title-urls.service";
import * as i12 from "./slider/slider.component.ngfactory";
import * as i13 from "./slider/slider.component";
import * as i14 from "@ngxs/store";
import * as i15 from "../../../common/core/ui/breakpoints.service";
import * as i16 from "../titles/components/media-item-header/media-item-header.component.ngfactory";
import * as i17 from "../titles/components/media-item-header/media-item-header.component";
import * as i18 from "../shared/footer/footer.component.ngfactory";
import * as i19 from "../shared/footer/footer.component";
import * as i20 from "./homepage.component";
var styles_HomepageComponent = [i0.styles];
var RenderType_HomepageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HomepageComponent, data: {} });
export { RenderType_HomepageComponent as RenderType_HomepageComponent };
function View_HomepageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_HomepageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ad-host", [["class", "homepage-ad-host"], ["slot", "ads.homepage"]], [[8, "id", 0]], null, null, i2.View_AdHostComponent_0, i2.RenderType_AdHostComponent)), i1.ɵdid(1, 245760, null, 0, i3.AdHostComponent, [i1.ElementRef, i1.Renderer2, i4.Settings, i5.CurrentUser, i6.LazyLoaderService], { slot: [0, "slot"] }, null)], function (_ck, _v) { var currVal_1 = "ads.homepage"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).randomId; _ck(_v, 0, 0, currVal_0); }); }
function View_HomepageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 2), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomepageComponent_2)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "media-grid", [["class", "full-width"]], null, null, null, i9.View_MediaGridComponent_0, i9.RenderType_MediaGridComponent)), i1.ɵdid(11, 49152, null, 0, i10.MediaGridComponent, [i11.TitleUrlsService], { items: [0, "items"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomepageComponent_3)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, "/lists", _v.context.$implicit.id); _ck(_v, 5, 0, currVal_2); var currVal_4 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_4); var currVal_5 = _v.context.$implicit.items; _ck(_v, 11, 0, currVal_5); var currVal_6 = (_v.context.index === 0); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_3); }); }
export function View_HomepageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slider", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 1).suspendAutoSlide = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 1).suspendAutoSlide = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_SliderComponent_0, i12.RenderType_SliderComponent)), i1.ɵdid(1, 114688, null, 0, i13.SliderComponent, [i14.Store, i11.TitleUrlsService, i15.BreakpointsService, i4.Settings, i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "media-item-header", [], [[4, "background-image", null], [2, "no-backdrop", null]], null, null, i16.View_MediaItemHeaderComponent_0, i16.RenderType_MediaItemHeaderComponent)), i1.ɵdid(3, 49152, null, 0, i17.MediaItemHeaderComponent, [], { transparent: [0, "transparent"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ad-host", [["class", "shared-ad-host"], ["slot", "ads.shared"]], [[8, "id", 0]], null, null, i2.View_AdHostComponent_0, i2.RenderType_AdHostComponent)), i1.ɵdid(5, 245760, null, 0, i3.AdHostComponent, [i1.ElementRef, i1.Renderer2, i4.Settings, i5.CurrentUser, i6.LazyLoaderService], { slot: [0, "slot"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "section", [["class", "lists"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomepageComponent_1)), i1.ɵdid(8, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "footer", [], null, null, null, i18.View_FooterComponent_0, i18.RenderType_FooterComponent)), i1.ɵdid(11, 49152, null, 0, i19.FooterComponent, [i4.Settings], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = true; _ck(_v, 3, 0, currVal_2); var currVal_4 = "ads.shared"; _ck(_v, 5, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.content$)); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).backgroundImage; var currVal_1 = i1.ɵnov(_v, 3).noBackdrop; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).randomId; _ck(_v, 4, 0, currVal_3); }); }
export function View_HomepageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "homepage", [], null, null, null, View_HomepageComponent_0, RenderType_HomepageComponent)), i1.ɵdid(1, 114688, null, 0, i20.HomepageComponent, [i14.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomepageComponentNgFactory = i1.ɵccf("homepage", i20.HomepageComponent, View_HomepageComponent_Host_0, {}, {}, []);
export { HomepageComponentNgFactory as HomepageComponentNgFactory };
