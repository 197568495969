import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var PeopleService = /** @class */ (function () {
    function PeopleService(http) {
        this.http = http;
    }
    PeopleService.prototype.getAll = function (params) {
        return this.http.get('people', params);
    };
    PeopleService.prototype.get = function (id) {
        return this.http.get('people/' + id);
    };
    PeopleService.prototype.create = function (payload) {
        return this.http.post('people', payload);
    };
    PeopleService.prototype.update = function (id, payload) {
        return this.http.put('people/' + id, payload);
    };
    PeopleService.prototype.delete = function (ids) {
        return this.http.delete('people', { ids: ids });
    };
    PeopleService.ngInjectableDef = i0.defineInjectable({ factory: function PeopleService_Factory() { return new PeopleService(i0.inject(i1.AppHttpClient)); }, token: PeopleService, providedIn: "root" });
    return PeopleService;
}());
export { PeopleService };
