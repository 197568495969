import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HomepageState } from './state/homepage-state';
import { Observable } from 'rxjs';
import { ToggleGlobalLoader } from '../../state/app-state-actions';
var HomepageComponent = /** @class */ (function () {
    function HomepageComponent(store) {
        this.store = store;
    }
    HomepageComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () { return _this.store.dispatch(new ToggleGlobalLoader(false)); });
    };
    tslib_1.__decorate([
        Select(HomepageState.content),
        tslib_1.__metadata("design:type", Observable)
    ], HomepageComponent.prototype, "content$", void 0);
    return HomepageComponent;
}());
export { HomepageComponent };
