import { AppHttpClient } from '../../../common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
var NewsService = /** @class */ (function () {
    function NewsService(http) {
        this.http = http;
    }
    NewsService.prototype.getAll = function (params) {
        return this.http.get('news', params);
    };
    NewsService.prototype.get = function (id) {
        return this.http.get('news/' + id);
    };
    NewsService.prototype.create = function (payload) {
        return this.http.post('news', payload);
    };
    NewsService.prototype.update = function (id, payload) {
        return this.http.put('news/' + id, payload);
    };
    NewsService.prototype.delete = function (params) {
        return this.http.delete('news', params);
    };
    NewsService.ngInjectableDef = i0.defineInjectable({ factory: function NewsService_Factory() { return new NewsService(i0.inject(i1.AppHttpClient)); }, token: NewsService, providedIn: "root" });
    return NewsService;
}());
export { NewsService };
